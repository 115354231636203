.group {
  margin-bottom: 1rem;
  background: var(--accent-20);
}

.group__container,
.group-overview__container {
  --grid-gutter: 1em;
}

.group__title {
  background: var(--accent, #def);
  cursor: pointer;
  padding: 1rem;
  counter-increment: group;
}

.group__content {
  position: relative;
}

.goal__goal {
  border-top: 2px solid white;
  background: var(--accent-60);
  padding: 1rem;
}

.group__content > .field,
.goal > .field,
.goal__basics,
.aspect__title {
  border-top: 2px solid white;
  background: var(--accent-20);
  padding: 1rem;
}

/* Counters */

.form {
  counter-reset: group;
}

.group__title h2 {
  display: flex;
}

.group__title h2::after {
  background: url("expand-more.svg");
  content: " ";
  display: inline-block;
  text-align: center;
  width: 1.3em;
  height: 1.3em;
  background-size: contain;
  background-repeat: no-repeat;
  flex: 0 0 1.3em;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

details[open] .group__title h2::after {
  background-image: url("expand-less.svg");
}

.counter::before {
  display: inline-block;
  text-align: center;
  background: #fff;
  box-shadow: 0 2px 0 5px #fff;
  min-width: 1em;
  height: 1em;
  margin-right: 0.75em;
}

.group__counter::before {
  content: counter(group, upper-alpha) " ";
}

.group {
  counter-reset: aspect;
  counter-reset: goal;
}

.aspect {
  counter-increment: aspect;
  counter-set: goal 0;
}

.aspect__counter::before {
  content: counter(group, upper-alpha) counter(aspect);
}

.aspect__title {
  background: var(--accent);
}

.goal__goal h4 {
  display: flex;
  font-size: 1.3125rem;
  gap: 0.5em;
}

.goal__goal h4::before {
  counter-increment: goal;
  content: counter(group, upper-alpha) counter(aspect) "." counter(goal) ".";
}

.goal {
  counter-reset: goal-fulfillment;
}

.goal__goal {
  counter-set: goal-fulfillment 0;
}

.goal__fulfillment > h5 {
  margin-bottom: 1em;
  font-size: 1rem;
  &::after {
    counter-increment: goal-fulfillment;
    content: " " counter(goal-fulfillment, upper-alpha);
  }
}

.goal__fulfillment > label {
  font-size: 1.3125rem;
  line-height: 1.3;
  margin-bottom: 1em;
}

.goal__fulfillment__choices > div {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (--media-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (--media-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  gap: 0.5rem;
}

/* Badges */

.goal__fields {
  float: right;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.25rem;
}

.goal__field {
  background: var(--white);
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
}

/* .goal__field--economy {
  --accent: var(--sulugreen);
}
.goal__field--environment {
  --accent: var(--lightsalmon);
}
.goal__field--society {
  --accent: var(--jordyblue);
} */

html {
  scroll-behavior: smooth;
}

h2,
h3,
h4 {
  scroll-margin: 5rem;
}

.group h2,
.aspect h3 {
  font-size: 1.45rem;
}

.goal h4 {
  font-size: 1.2rem;
}
