.sticky-wrap {
  display: none;
  position: sticky;
  top: calc(1em + 3em);
}

.scroller {
  margin-bottom: 1em;

  flex-direction: column;
  gap: 1em;

  padding: 1.5em;
  border-radius: 1em;
  background: var(--accent-60);

  overflow: hidden;

  &--overview {
    counter-increment: group;
    position: unset;
    display: flex;
  }
}

.scroller__container--overview {
  counter-reset: group;
}

details[open] + .scroller__container .sticky-wrap {
  display: block;
}

.scroller__top {
  display: flex;
  justify-content: space-between;
  max-height: 6.25rem;
}

.scroller__canvas {
  margin-left: auto;
  max-height: 100%;
  max-width: 100%;
}
.scroller__goal-properties {
  font-size: 1em;
  @media (--media-md) {
    font-size: 0.66em;
  }
  @media (--media-lg) {
    font-size: 0.9em;
  }
  li::before {
    content: " ";
    display: inline-block;
    border: 1px solid var(--black);
    width: 0.7em;
    height: 0.7em;
    margin-right: 0.2em;
    background: var(--control-color);
  }
  li::after {
    content: ": " attr(data-count);
  }
}

.chart {
  transform: rotate(270deg);
  transform-origin: 50%;
  --control-color: var(--gray);
  --pie-offset: 0;
  --pie-size: 0;
  stroke-width: 200;
  fill: none;
  .pie {
    stroke-dasharray: 0, var(--pie-offset), var(--pie-size), calc(3.14 * 100%);
    stroke: var(--control-color);

    transition: stroke-dasharray 0.3s ease-out;
  }
  .pie--background {
    @media screen {
      filter: drop-shadow(-3px 3px 5px var(--black));
    }
    stroke: var(--white);
  }
}

.does_not_apply {
  --control-color: var(--chart-does-not-apply);
}
.pending {
  --control-color: var(--chart-pending);
}
.not_fulfilled {
  --control-color: var(--chart-not-fulfilled);
}
.partially_fulfilled {
  --control-color: var(--chart-partially-fulfilled);
}
.fulfilled {
  --control-color: var(--chart-fulfilled);
}

.scroller--overview--all {
  color: var(--white);
  margin-right: auto;

  .scroller {
    display: flex;
    flex-direction: row;
  }

  .scroller__top {
    display: block;
    flex: 1 0 35%;
    max-height: 100%;
  }
  .scroller__canvas {
    margin-left: 0;
    margin-top: 0.5em;
    margin-right: auto;
  }
  .scroller__goal-properties {
    margin-top: auto;
    margin-bottom: 1em;
  }
}
