:root {
  --black: #222;
  --white: #fff;
  --gray: #cacaca;

  --turquoise: #6ae7cd;
  --goldenrod: #fbd970;
  --jordyblue: #7baaef;
  --salmon: #f5887a;
  --sulugreen: #c9ef71;
  --lavender: #b08de8;
  --lightsalmon: #f8a772;
  --skyblue: #61d4ef;
  --baselblue: #1c3947;
  --baselgreen: #a7bfad;

  --baselblue-60: #204454;
  --baselblue-20: #2a4b59;

  --goldenrod-60: #fce8a9;
  --goldenrod-20: #fef7e2;

  --turquoise-60: #a6f0e1;
  --turquoise-20: #e1faf5;

  --salmon-60: #f9b8af;
  --salmon-20: #fde7e4;

  --lavender-60: #d0bbf1;
  --lavender-20: #efe8fa;

  --skyblue-60: #a0e5f6;
  --skyblue-20: #dff6fc;

  --gray-60: #cacaca;
  --gray-20: #e9e9e9;

  --error-fg: #ff0000;
  --error-bg: #ffd7d7;
  --warning: orange;

  --chart-does-not-apply: #afafaf;
  --chart-pending: #dddddd;
  --chart-not-fulfilled: #ff0000;
  --chart-partially-fulfilled: #ffb800;
  --chart-fulfilled: #67eb29;

  --z-region-nav: 99;

  /*
  --shadow: 0 0 4px 0 rgba(50, 50, 150, 0.3);

  --general-block-margin: 1.5rem;
  --general-margin: 2rem;
  --general-margin-x2: 3.5rem;
  --line-height-footer: 1.5;
  --general-transition-speed: 0.3s;

  --radius-xl: 96px;
  --radius-lg: 48px;
  --radius-md: 32px;
  --radius-sm: 24px;
  --radius-xs: 16px;
  */
}

.accent-turquoise {
  --accent: var(--turquoise);
  --accent-60: var(--turquoise-60);
  --accent-20: var(--turquoise-20);
}
.accent-goldenrod {
  --accent: var(--goldenrod);
  --accent-60: var(--goldenrod-60);
  --accent-20: var(--goldenrod-20);
}
.accent-jordyblue {
  --accent: var(--jordyblue);
}
.accent-salmon {
  --accent: var(--salmon);
  --accent-60: var(--salmon-60);
  --accent-20: var(--salmon-20);
}
.accent-sulugreen {
  --accent: var(--sulugreen);
}
.accent-lavender {
  --accent: var(--lavender);
  --accent-60: var(--lavender-60);
  --accent-20: var(--lavender-20);
}
.accent-lightsalmon {
  --accent: var(--lightsalmon);
}
.accent-skyblue {
  --accent: var(--skyblue);
  --accent-60: var(--skyblue-60);
  --accent-20: var(--skyblue-20);
}
.accent-gray {
  --accent: var(--gray);
  --accent-60: var(--gray-60);
  --accent-20: var(--gray-20);
}
.accent-baselblue {
  --accent: var(--baselblue);
  --accent-60: var(--baselblue-60);
  --accent-20: var(--baselblue-20);
}

#f3cc {
  --f3cc-accent-color: var(--goldenrod);
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Roboto, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: var(--black);
  background: var(--white);
}

body > .container {
  flex: 1 0 auto;
  width: 100%;
}

main {
  flex: 1 0 100%;
}

.responsive-image {
  margin: 0;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.responsive-image__img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain";
}

.responsive-embed {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
