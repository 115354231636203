.region-nav {
  counter-reset: group, aspect, goal;

  &-container {
    transform: translateX(100%);
    .region-nav-is-open & {
      transform: unset;
      transition: transform 0.3s ease-in-out;
    }
    .region-nav-is-closed & {
      transition: transform 0.3s ease-in-out;
    }

    position: fixed;
    display: block;
    top: 0;
    right: 0;
    height: 100vh;

    padding: 5rem;

    overflow-y: auto;

    width: clamp(20rem, 50vw, 90vw);

    background-color: rgba(255, 255, 255, 1);

    z-index: var(--z-region-nav);

    box-shadow: 0px 2px 2px black;

    &::before,
    &::after {
      content: " ";
      height: 5rem;
      width: 100%;
      position: fixed;
      display: block;
    }

    &::before {
      top: 0;

      background: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
    }

    &::after {
      bottom: 0;

      background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }

  display: flex;
  flex-flow: column;
  gap: 0.33em;

  .nav-group {
    counter-reset: aspect, goal;
    counter-increment: group;

    display: flex;
    flex-flow: column;
    gap: 0.33rem;

    padding-bottom: 1rem;
    padding-top: 1rem;

    border-bottom: 1px solid black;
    &:last-child {
      border-bottom: none;
    }
  }

  .menu-item {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    scroll-margin: 30%;

    color: black;
    text-decoration: none;

    &::before {
      font-weight: bold;
      font-size: 1.2rem;
    }

    &--group {
      background-color: var(--accent);
      &::before {
        content: counter(group, upper-alpha) " ";
      }
    }
    &--aspect {
      margin-left: 1rem;
      counter-reset: goal;
      counter-increment: aspect;
      background-color: var(--accent-60);

      &::before {
        content: counter(group, upper-alpha) " " counter(aspect) ". ";
      }
    }
    &--goal {
      margin-left: 2rem;
      counter-increment: goal;
      background-color: var(--accent-20);

      &::before {
        content: counter(group, upper-alpha) " " counter(aspect) "."
          counter(goal) ". ";
      }
    }
  }
}

.region-nav-toggle-open {
  position: sticky;
  top: 1em;
  margin-bottom: 1em;

  height: 2em;

  .icon {
    display: inline-block;
    margin-left: 1em;
    height: 1em;
    width: 1em;
    background: url("menu.svg") no-repeat center;
    transform: translateY(0.15em);
  }
}

.region-nav-toggle-close {
  position: sticky;
  top: 50%;
  height: 2em;

  transform: rotate(-90deg);
  transform-origin: left 180%;

  .icon {
    margin-left: 1em;
    display: inline-block;
    height: 1em;
    width: 1em;
    background: url("expand-more.svg") no-repeat center;
    transform: translateY(0.15em);
  }
}

.region-nav-toggle-grid {
  direction: rtl;
  position: relative;
}
.region-nav-toggle-container {
  position: absolute;
  top: 0;
}
