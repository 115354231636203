.header {
  background: url("header-bg.png");
}

.header__container {
  display: flex;
  align-items: center;
}

.header__logo {
  width: 100%;
  display: block;
  margin: 1rem 0;
}
