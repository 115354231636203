.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.text-right {
  text-align: right !important;
}

.no-style {
  color: inherit;
  text-decoration: none;
}

.hidden {
  display: none !important;
}

.show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.print-only {
  display: none;
  @media print {
    display: unset;
  }
}
