h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 1em;
}

h1,
h2 {
  margin-top: 2em;
}

h3,
h4 {
  margin-top: 1.3em;
}

p,
li {
  line-height: 1.3;
  /* max-width: 60ch; */
}

.form,
.report {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}
