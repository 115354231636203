.header__navigation {
  background: var(--baselblue-60);
}

.main-nav,
.sub-nav {
  display: flex;
  font-size: 1rem;
  font-weight: bold;
}

.main-nav__link {
  text-decoration: none;
  color: var(--white);
  padding: 1em 2em;

  background: var(--baselblue-60);

  &.active {
    background: var(--baselblue-20);
  }

  &:last-of-type::after {
    border-right: none;
  }
}

.sub-nav__link {
  padding: 0.5em 0;
  text-decoration: none;
  color: var(--gray);

  &:not(:last-child) {
    margin-right: 2em;
  }
  &:not(:first-child) {
    margin-left: 2em;
  }

  &.active {
    color: var(--black);
  }
}

.sub-nav {
  margin: 2em 0;
}

.sub-nav .separator {
  border-right: 1px solid var(--gray);
}
