.footer {
  background-color: var(--baselblue);
  color: var(--white);
  margin-top: 2rem;
  padding: 1rem 0;
}

.footer a {
  color: var(--baselgreen);
  text-decoration: none;

  font-weight: bold;

  &:hover,
  &:focus {
    color: unset;
    text-decoration: underline;
  }
}
