.form {
  .widget--radioselect > div,
  .widget--checkboxselectmultiple > div {
    list-style: none;
    padding: 0;
  }

  .errors {
    display: flex;
    align-items: center;
    line-height: 1.3;

    padding: 0.5rem;
    border: 1px solid;
    border-radius: 999px;
    margin-bottom: 1rem;

    color: var(--error-fg);

    i {
      font-size: 2em;
    }
  }

  .errors--warning {
    color: var(--warning);
  }

  .errors__text {
    margin-left: 0.5rem;
  }

  .is-invalid {
    input[type="text"],
    input[type="email"],
    input[type="number"],
    select,
    textarea {
      border-color: var(--error-fg);
      background: var(--error-bg);
    }
  }

  .field__errors {
    color: var(--error-fg);
  }

  &[data-textarea-autoresize] {
    textarea {
      display: block;
      overflow: hidden;
      resize: none;
    }
  }
}

.field {
  position: relative;
  padding: 0;
  margin-bottom: var(--general-margin);

  > label {
    display: block;
    margin-bottom: 0.5rem;

    &.required {
      &:after {
        content: "*";
      }
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    display: block;
    width: 100%;
    max-width: 70ch;
    border: 2px solid;
    border-radius: 2px;
    padding: 0.25rem;

    &.--filled {
      border: 1px solid var(--ui-green);
      color: var(--ui-green);

      &:hover {
        border: 1px solid var(--ui-green);
      }
    }

    &:focus {
      box-shadow: 3px 2px 11px 0 var(--black);
      outline: none;
    }
  }
}

.field-error {
  display: none;
  color: var(--ui-red);
}

.field--error {
  input {
    &::placeholder,
    & {
      color: var(--ui-red);
      border-color: var(--ui-red);
    }
  }

  .field-error {
    display: block;
  }
}

.field-help {
  margin-bottom: 0.5rem;
}

.widget--select {
  select {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem 2.5rem;
    border: 1px solid var(--black);
    border-radius: var(--radius-sm);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.widget--textarea {
  label {
    font-weight: bold;
  }
}

.widget--radioselect {
  > div {
    display: flex;
    gap: 1.5rem;
    flex-flow: row wrap;
    margin: 0;
  }

  input {
    display: none;
  }

  div label {
    position: relative;
    cursor: pointer;

    background: url(radio_button_unchecked.svg) no-repeat 0 center;
    background-size: contain;

    padding-left: 1.75em;

    height: 1.5rem;
    display: flex;
    align-items: center;
  }

  div input:checked + label {
    background-image: url(radio_button_checked.svg);
  }
}

.widget--hiddeninput {
  display: none;
}

.button {
  border: 1px solid;
  border-radius: 2px;
  background: #fff;
  font-weight: bold;
  padding: 0.25rem 1rem;
  box-shadow: 2px 2px 0 0 #000;
  cursor: pointer;
}

.form--cover {
  .cover__container {
    padding: 1rem;
    background: var(--gray-20);
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  label {
    font-weight: bold;
  }
}

.form-control {
  margin: 2rem 0;
}
