:root {
  --container-width: 60rem;
  --grid-columns: 12;
  --grid-gutter: 2.5rem;
}

.container {
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;

  padding-left: calc(0.5 * var(--grid-gutter));
  padding-right: calc(0.5 * var(--grid-gutter));

  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

main > .container:first-child {
  margin-top: 2rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
}

.grid--gx {
  column-gap: var(--grid-gutter);
}

.grid--gy {
  row-gap: var(--grid-gutter);
}

.cell {
  --width-sm: var(--grid-columns); /* Default width */
  --width: var(--width-sm);
  grid-column-end: span var(--width);
}

@media (--media-md) {
  .cell {
    --width-md: var(--width-sm);
    --width: var(--width-md);
  }
}

@media (--media-lg) {
  .cell {
    --width-lg: var(--width-md);
    --width: var(--width-lg);
  }
}

@for $i from 1 to 12 {
  .cell.sm-$i {
    --width-sm: $i;
  }
  .cell.md-$i {
    --width-md: $i;
  }
  .cell.lg-$i {
    --width-lg: $i;
  }
}
