.message {
  display: flex;
  align-items: center;
  line-height: 1.3;

  padding: 0.5rem;
  border: 2px solid;
  margin-bottom: 0.5rem;

  &.error {
    border-color: var(--error-fg);
    background: var(--error-bg);
  }

  &.success {
    color: var(--success);
  }

  &.warning {
    color: var(--warning);
  }
}

.message__text {
  margin-left: 0.5rem;
}
