.report__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  counter-reset: goal-fulfillment;
}

.report__logo {
  max-width: 30rem;
}

.report__group {
  counter-reset: aspect;
  counter-reset: goal;
  counter-increment: group;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.report__aspect {
  counter-increment: aspect;
  counter-set: goal 0;
}

.report__group__title,
.report__cover-data__title,
.report__aspect {
  background: var(--accent);
  padding: 1rem;

  h2,
  h3 {
    font-size: 1rem;
  }
}

.report__goal__title {
  &::before {
    content: counter(group, upper-alpha) counter(aspect) "." counter(goal) ". ";
    font-weight: bold;
  }
  &--counter {
    counter-increment: goal;
    counter-set: goal-fulfillment 0;
  }
}

.report__cover-data,
.report__goal {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  p:not(:last-of-type) {
    margin-bottom: 1em;
  }

  &__title {
    padding: 0.5rem 1rem;
    background: var(--accent-60);
    display: flex;
    gap: 0.5em;
  }

  &__details {
    display: flex;
    gap: 0.25rem;
  }

  &__details--details {
    padding: 1rem;
    flex-grow: 1;
    background: var(--accent-20);
  }

  &__details--control {
    flex-shrink: 0;
    flex-basis: 1rem;
    background: var(--control-color);
  }

  h5 {
    margin-bottom: 1em;
    &::after {
      content: " " counter(goal-fulfillment, upper-alpha);
    }
  }
}

.criteria {
  counter-increment: goal-fulfillment;
}

.invisible {
  position: absolute;
  & > * {
    display: none;
  }
}

.report__aspect.invisible {
  padding: 0;
}

.report {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  counter-reset: group;

  dt {
    font-weight: bold;
  }
}

.report-qr-code {
  display: block;
  color: transparent;
  text-align: right;
}

.report__cover-data__details--details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.print-button__container {
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
    color: inherit;
  }
}

.report {
  margin: 4rem auto;
  max-width: 70em;
}

@media print {
  html {
    font-size: 8pt;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .report {
    display: block;
    max-width: 70em;
    .report__group {
      display: block;
    }
    .report__container {
      display: block;
      margin-top: 0.2rem;
      margin-bottom: 1rem;
    }
    .report__goal__details {
      /* display: block; */
      position: relative;
    }
    .report__goal__details--control {
      position: absolute;
      top: 0;
      right: 0;
      height: 2rem;
      aspect-ratio: 1;
    }
    .report__container--additional {
      display: block;
      margin-top: 1rem;
    }
    .report__aspect {
      display: block;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      break-inside: avoid;
    }
    .report__goal {
      display: block;
      margin-bottom: 0.2rem;
      break-inside: avoid;
    }
  }

  /* .report__container,
  .report__container--additional {
    margin-bottom: 1rem;
    display: inline-block;
    width: 100%;
    > * {
      display: inline-block;
      width: 100%;
      margin-bottom: 0.25rem;
    }
  } */

  #f3cc,
  nav,
  footer,
  .content-title,
  header,
  .dont-print {
    display: none !important;
  }

  @page {
    margin: 1cm;
  }
}
